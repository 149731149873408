import { HeartIcon } from '@heroicons/react/outline'

const items = [
    {
        q: 'Does Mirror Pass still mirror other Alphas',
        a: 'No, we officially ended mirroring back in June. All of our Alpha and Plays have been from within our Community!',
    },
    {
        q: 'How many $Shards will I earn if I stake my Mirror Pass?',
        a: 'By staking a Mirror Pass, you earn 10 $Shards/Day.',
    },
    {
        q: 'How do I get access to Reflektor',
        a: 'We are allowing Mirror Pass holders join in waves, simply hold and stake a Mirror Pass!',
    },

]

export const FAQ = () => {
    return (
        <section className='h-full w-full  flex flex-col justify-center items-center z-[10] px-4' id={'faq'}>
            <div className='w-full max-w-7xl '>
                <h4 className='text-white text-2xl font-semibold mb-4'>FAQs</h4>
                <p className='text-white text-sm max-w-2xl mb-10'>Have a question about Mirrorpass?</p>{' '}
                <ul className='space-y-10 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:gap-y-10'>
                    {items.map((elem, i) => (
                        <li key={`faq-${i}`} className={'flex flex-col gap-4'}>
                            <div className='p-2 bg-[#2f2F6d] w-min rounded-full'>
                                <HeartIcon className='w-6 h-6 text-white' />
                            </div>
                            <div className='flex flex-col gap-1'>
                                <dt className='font-semibold text-white'>{elem.q}</dt>
                                <dd className='mt-3 text-gray-400'>{elem.a}</dd>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    )
}
