import Image from 'next/image'
import ProductList from './ProductList'
import CardImg from '/public/images/card.png'
import ArtDeco from '/public/images/rectangle.png'

export const Benefits = () => {
    return (
        <section className='h-full w-full  flex flex-col justify-center items-center z-[10] '>
            <div className='relative w-full max-w-7xl justify-center items-center flex flex-col pt-24'>
                {/*<p className="text-blue-default text-sm text-center font-bold  z-[10] mb-4">Features</p>*/}
                <h4 className='mb-4 text-white font-semibold  text-2xl text-center max-w-lg px-4 z-[10] leading-loose'>
                    Find out why Mirrorpass is the best
                </h4>
                <p className='text-white text-sm max-w-2xl text-center'>
                    Mirrorpass is a unique NFT opportunity to grab access to NFT botting software - acquiring all your
                    favorite NFTs & more.
                </p>
                <div className={'hidden lg:block absolute top-[6REM] left-0 aspect-[16/9] h-auto w-auto'}>
                    <Image src={CardImg} height={'180px'} width={'180px'} alt={''} />
                </div>
                <div className={'hidden lg:block absolute top-0 right-0 aspect-[16/9] h-auto w-auto'}>
                    <Image src={ArtDeco} height={'180px'} width={'180px'} alt={''} />
                </div>
            </div>
            <ProductList />
        </section>
    )
}
