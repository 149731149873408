import type { NextPage } from "next";
import Layout from "../modules/layout";
import Hero from "../modules/landing-page/Hero";
import { Features } from "../modules/landing-page/Features";
import { Benefits } from "../modules/landing-page/Benefits";
import { FAQ } from "../modules/landing-page/FAQ";
import Image from "next/image";
import Bridge from "/public/images/Bridge.png";

const Home: NextPage = () => {
  return (
    <Layout title="Mirror Pass">
      <article className="w-full min-w-full flex items-center justify-center flex-col">
        <Hero />
        <div className="relative h-48 w-full">
          <Image src={Bridge} alt="" layout="fill" objectFit="contain" />
        </div>
        <Features />
        <Benefits />
        <FAQ />
      </article>
    </Layout>
  );
};

export default Home;
