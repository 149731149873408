import { StaticImageData } from 'next/image'
import Product from './Product'
import Marketplace from '/public/images/Marketplace.png'
import Bot from '/public/images/bot_ui.png'
import Analytics from '/public/images/analytics.png'
import Factory from '/public/images/Factory.png'
import {DesktopComputerIcon, ShoppingBagIcon, CubeIcon, CashIcon} from "@heroicons/react/outline"

export type Product = {
    name: string
    icon: React.ReactNode
    description: string
    image: StaticImageData
    facts: Array<string>
    aspectStyles?:string
}

const products = [
    {
        name: 'Introducing Reflektor',
        description:
            'Reflektor is an automated software designed for user experience and performance. This collection will be a continuation of the Mirrorverse, brought to you by Mirror Pass.',
        facts: ['Automated Opensea & Rarity Sniping', 'Bulk Minting', 'Wallet Management', '24/7 Monitoring'],
        image: Bot,
        aspectStyles: "aspect-w-16 aspect-h-12",
        icon: <DesktopComputerIcon className="w-6 h-6 text-white" />
    },
    {
        name: 'Participate in our Marketplace',
        description: 'The Mirror Pass marketplace features rewards for holding a Mirror Pass or spending $Shards.',
        facts: ['Whitelists', 'Raffles', 'Giveaways', '$Shards Events'],
        image: Marketplace,
        aspectStyles: "aspect-w-16 aspect-h-12",
        icon: <ShoppingBagIcon className="w-6 h-6 text-white" />
    },
    {
        name: 'Meet Analytics',
        description:
            'We are building an all-in-one analytics tool for Mirror Pass holders. Our goal is to provide as much features within our dAPP.',
        facts: ['Contract Sniffing', 'Live Mint Monitor', 'Live Trending Mints'],
        image: Analytics,
        aspectStyles: "aspect-w-16 aspect-h-12",
        icon: <CubeIcon className="w-6 h-6 text-white" />
    },
    {
        name: 'Begin Staking',
        description:
            'Stake your Mirror Passes to accumulate 10 $Shards/day. Additionally, each staked Mirror Pass increases your amount of entries for raffles and giveaways.',

        facts: [],
        image: Factory,
        aspectStyles: "aspect-w-16 aspect-h-12",
        icon: <CashIcon className="w-6 h-6 text-white" />
    },
]

const ProductList = () => {
    return (
        <ul className="mt-24 mb-24 w-full max-w-7xl mx-auto">
            {products.map((elem, i) => (
                <Product key={elem.name} {...elem} isEven={i % 2 === 0} hasNext={i !== products.length - 1} />
            ))}
        </ul>
    )
}

export default ProductList
