
import { CheckIcon } from '@heroicons/react/solid'
import Image from 'next/image'
import { Product } from './ProductList'
import ReflektorImg from '/public/images/reflektor.png'
import Splitter from '/public/images/splitter.png'

const Product = (props: Product & { isEven: boolean; hasNext: boolean }) => {
    const { isEven, name, description, facts, hasNext, image, aspectStyles , icon} = props
    return (
        <li className="flex flex-col justify-center items-center px-4">
            <div
                className={`w-full flex flex-col ${
                    isEven ? 'lg:flex-row' : 'lg:flex-row-reverse'
                } relative mt-8 mb-8 h-md gap-12`}
            >
                <div className={`w-full flex-[.5]`}>
                    <div className="pt-4 pb-4 flex flex-col gap-4">
                        <div className="bg-[#2f2F6d] rounded-full p-2 w-min ">
                            {icon}
                        </div>
                        <h6 className="text-white font-bold text-xl"> {name}.</h6>
                        <p className="text-left w-full text-gray-400">{description}</p>
                        <ul className="gap-4 flex flex-col">
                            {facts.map((elem, i) => (
                                <li key={`${name}-${i}`} className={'flex flex-row gap-4 justify-start items-center'}>
                                    <div className="bg-[#2f2f6d] p-1 rounded-full">
                                        <CheckIcon className="h-5 w-5 text-white" />
                                    </div>
                                    <p className="text-left w-full text-gray-500">{elem}</p>{' '}
                                </li>
                            ))}
                        </ul>{' '}
                    </div>
                </div>
                <div className={`w-full } relative flex-[.5]`}>
                    <div className="block  lg:block bg-[#4c52883e] rounded-lg  border border-[#3f4456]  h-full p-4 md:p-16">
                        <div className={`h-[200px]  sm:h-[300px] relative ${aspectStyles ?? ""}`}>
                            <Image
                                layout="fill"
                                src={image ?? ReflektorImg}
                                alt={'Reflektor software UI screenshot'}
                                className={'z-[1] rounded-lg'}
                            />
                        </div>{' '}
                    </div>
                    <div
                        className={`absolute ${
                            isEven ? 'right-[-10rem] bg-gradient-to-r' : ' left-[-10rem] bg-gradient-to-l'
                        } z-[0] top-0 sm:top-[-4rem] rounded-full h-36 sm:h-[600px]  w-[56rem]  from-blue-default via-blue-900 to-black opacity-25 blur-3xl `}
                    />
                </div>
            </div>
            {hasNext ? (
                <div className={`hidden sm:block w-1/2 h-[140px] relative ${!isEven ? `scale-x-[-1]` : ''}`}>
                    <Image src={Splitter} layout={'fill'} alt={''} />
                </div>
            ) : null}
        </li>
    )
}

export default Product
