const Hero = () => {
  return (
    <section className="w-full max-w-7xl px-4 relative flex flex-col justify-center items-center mt-12 mb-12 xs:overflow-y-clip xs:overflow-x-visible sm:overflow-visible">
      <p className="text-white text-sm text-center  z-[1]">
        Alpha. Botting. Staking. Analytics. Community.
      </p>
      <h1 className="text-white font-semibold  text-3xl sm:text-5xl text-center max-w-lg mt-4 mb-4 z-[1] leading-loose">
        Gap Between Whales and Regular Investors
      </h1>
      <p className="text-white text-sm text-center  z-[1]">
        We are inevitable.
      </p>
    </section>
  );
};

export default Hero;
