export const Features = () => {
    return (
        <section className='w-full flex flex-col justify-center items-center mt-24 bg-[#11111f]  z-[1]' id={'features'}>
            <div className='w-full max-w-7xl px-4 relative flex flex-col mt-12 gap-4 '>
                <h4 className='text-white text-2xl font-semibold '>WTF is Mirror Pass?</h4>
                <p className='text-white text-sm max-w-2xl'>
                    Mirror Pass is a community curated Alpha Group designed for experienced and new traders. Our mission
                    is to bridge the gap between whales and regular investors by tearing down arbitrary walls for an
                    affordable price.
                </p>{' '}
                <p className='text-white text-sm max-w-2xl'>
                    Navigate your way around Web3, and research projects with other Mirror Pass holders! We built the
                    first community curated Alpha Group, where there are no official Alpha analysts.
                </p>
                <p className='text-white text-sm max-w-2xl'>
                    Our community is the alpha, and with the dedication and eagerness of our members... we&apos;ve been
                    the first to discover many profitable plays.
                </p>
            </div>
        </section>
    )
}
